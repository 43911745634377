<template>
  <div>
    <el-form :inline="true" :model="filter" size="mini">
      <el-form-item label="开始日期">
        <el-date-picker
          value-format="yyyy-MM-dd"
          v-model="filter.begin"
          type="date"
          placeholder="选择日期"
          style="width:150px"
          :clearable="false"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="结束日期">
        <el-date-picker
          value-format="yyyy-MM-dd"
          v-model="filter.end"
          type="date"
          placeholder="选择日期"
          style="width:150px"
          :clearable="false"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="供应商">
        <base-select info="Supplier" v-model="filter.supplierId"></base-select>
      </el-form-item>
      <el-form-item label="物资">
        <base-select info="Goods" v-model="filter.goodsId"></base-select>
      </el-form-item>
      <el-form-item label="项目">
        <project-select v-model="filter.projectId"></project-select>
      </el-form-item>
      <el-form-item label="仓库">
        <base-select info="Store" v-model="filter.storeId"></base-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getData" icon="el-icon-refresh">刷新</el-button>
        <el-button type="success" @click="excel">
          <span class="iconfont iconexcel" style="font-size:12px;"></span>
          导出
        </el-button>
      </el-form-item>
    </el-form>
    <pl-table
      :data="data"
      border
      stripe
      :height="tableHeight"
      :row-height="rowHeight"
      use-virtual
      ref="table"
      @row-dblclick="dblclickTable"
      :row-class-name="tableRowClassName"
    >
      <el-table-column label="单据日期" prop="billDate" width="100" show-overflow-tooltip>
        <template slot-scope="scope">{{scope.row.billDate|datetime}}</template>
      </el-table-column>
      <el-table-column label="单据编号" prop="billCode" show-overflow-tooltip></el-table-column>
      <el-table-column label="项目/仓库" prop="locationName" show-overflow-tooltip></el-table-column>
      <el-table-column label="物资编码" prop="goodsCode" show-overflow-tooltip></el-table-column>
      <el-table-column label="物资名称" prop="goodsName" show-overflow-tooltip></el-table-column>
      <el-table-column label="规格" prop="goodsSpec" show-overflow-tooltip></el-table-column>
      <el-table-column label="单位" prop="goodsUnit" show-overflow-tooltip></el-table-column>
      <el-table-column label="数量" prop="quantity" show-overflow-tooltip></el-table-column>
      <el-table-column label="单价" prop="price" show-overflow-tooltip></el-table-column>
      <el-table-column label="金额" prop="amount" show-overflow-tooltip>
        <template slot-scope="scope">{{scope.row.amount|thousands}}</template>
      </el-table-column>
      <el-table-column label="备注" prop="remark" show-overflow-tooltip></el-table-column>
    </pl-table>
  </div>
</template>

<script>
import BaseSelect from "@/components/BaseSelect.vue";
import ProjectSelect from "@/components/ProjectSelect.vue";
import setName from "@/common/setName";
import toExcel from "@/common/toExcel";
import tableHeight from "@/common/tableHeightMixins";
export default {
  mixins: [tableHeight],
  components: {
    BaseSelect,
    ProjectSelect,
  },
  data() {
    return {
      filter: {
        begin: this.$moment().subtract(1, "months").format("YYYY-MM-DD"),
        end: this.$moment().format("YYYY-MM-DD"),
      },
      data: [],
    };
  },
  activated() {
    this.getData();
  },
  methods: {
    tableRowClassName({ row }) {
      if (!row.id) {
        return "bold-row";
      } else {
        return "";
      }
    },
    getData() {
      let params = { ...this.filter };
      if (!params.begin) {
        this.$message.error("请选择开始日期！");
        return;
      }
      if (!params.end) {
        this.$message.error("请选择结束日期！");
        return;
      }
      if (params.projectId && params.storeId) {
        this.data = [];
        return;
      }
      let loading = this.$loading({
        lock: true,
        text: "加载中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.$get("SupplierPurchase/Detail", params)
        .then((r) => {
          let res = [];
          r.forEach((item) => {
            item.amount = this.amountFormat(item.price * item.quantity);
            //1项目
            if (item.billType == 1) {
              setName("Project", item, "locationId", "locationName");
            }
            //2仓库
            if (item.billType == 2) {
              setName("Store", item, "locationId", "locationName");
            }
            let supplier = res.find((e) => e.billCode == item.supplierCode);
            if (!supplier) {
              res.push({
                billCode: item.supplierCode,
                locationName: item.supplierName,
                amount: item.amount,
              });
            } else {
              supplier.amount += item.amount;
            }
            res.push(item);
          });
          this.data = res;
        })
        .finally(() => {
          loading.close();
        });
    },
    excel() {
      let list = [];
      this.data.forEach((item) => {
        list.push({
          billDate: item.billDate
            ? this.$moment(item.billDate).format("YYYY-MM-DD")
            : "-",
          billCode: item.billCode,
          locationName: item.locationName,
          goodsCode: item.goodsCode,
          goodsName: item.goodsName,
          goodsSpec: item.goodsSpec,
          goodsUnit: item.goodsUnit,
          quantity: item.quantity,
          price: item.price,
          amount: item.amount,
          remark: item.remark,
        });
      });
      let header = [
        "单据日期",
        "单据编号",
        "项目/仓库",
        "物资编码",
        "物资名称",
        "规格",
        "单位",
        "数量",
        "单价",
        "金额",
        "备注",
      ];
      let column = [
        "billDate",
        "billCode",
        "locationName",
        "goodsCode",
        "goodsName",
        "goodsSpec",
        "goodsUnit",
        "quantity",
        "price",
        "amount",
        "remark",
      ];
      toExcel(header, column, list, "供应商供货明细");
    },
    dblclickTable(row) {
      let id = row.id;
      if (!id) {
        return;
      }
      if (this.hasPermission("Purchase")) {
        this.$router.push({
          name: "PurchaseItem",
          query: { id: id },
        });
      }
    },
  },
};
</script>

<style>
</style>